import { GetStaticProps } from "next";
import Head from "next/head";
import Layout from "../components/Layout";
import Page from "../components/Page";
import { usePreviewMode, useStoryblok } from "../hooks";

import client from "../api/sdk/clients/apolloClient";
import { getSdk } from "../api/sdk/getSdk";

const Container = ({data, navigation, preview}) => {

  const previewMode = usePreviewMode(preview);
  const story = useStoryblok(data, preview);
    
  const {
    background_image,
    background_video,
    body,
    component,
    gallery_images,
    heading,
    lead,
    meta_description,
    meta_keywords,
    title,
    slug,
    anchor_navigation
  } = story.content;

  return (
    <Layout
      backgroundImage={background_image}
      backgroundVideo={background_video}
      galleryImages={gallery_images}
      heading={heading}
      lead={lead}
      navigation={navigation}
      preview={previewMode}
      slug={slug}
      anchorNavigation={anchor_navigation?.[0]}
    >
      <Head>
        <title>{title.replace(/[\[\]']+/g, '').replace(/['"]+/g, '')}</title>
        <meta name="description" content={meta_description} />
        <meta name="keywords" content={meta_keywords} />
      </Head>
      {component === 'Page' && <Page body={body} />}
    </Layout>
  );
};

export const dataSdk = getSdk(client);

export const getStaticProps: GetStaticProps = async ({
  preview = false
}) => {
  const data = await dataSdk.getPageItem({ slug: '/startseite' });
  const navigation = await dataSdk.getMainNavigation();

  
  return {
    props: {
      data: data.data.PageItem,
      navigation: navigation.data.LayoutItem,
      preview,
    },
    revalidate: 60
  };
};

export default Container;
